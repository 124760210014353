import api from './api';
import env from '@beam-australia/react-env';

const contextPath = env('API_CONTEXT_PATH');

const loginApi = {
    login: token => fetch(`${contextPath}/login`, {
        method: 'POST',
        headers: {
            Authorization: token,
        },
    }),

    logout: () => {
        return api.postSecure('/logout');
    },

    saveLoginHistory: () => {
        return api.postSecure('/user/login-history');
    },

    token: (code, isOkta) => api.getSecure(`/auth/token?code=${code}${isOkta ? '&from=okta' : ''}`),

    authLogout: () => {
        return api.postSecure('/auth/logout');
    },
};

export default loginApi;