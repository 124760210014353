import React from 'react';
import {Button} from '@snsw/react-component-library';
import {Button as GelButton} from '@snsw-gel/react';
import {analyticsUtil} from '../utilities/analyticsUtil';

export const ButtonWithAnalytics = (props) => {
    // allows clients to set or override props for Google Analytics
    const {gaProps = {}} = props;

    const handleClick = (e) => {
        analyticsUtil.pushComponentAnalytics(
            {
                type: !!props.variant ? `button_${props.variant}` : 'button_primary',
                text: e.target.textContent,
                sectionId: 'page_content',
                destUrl: props.href,
                ...gaProps
            },
        );

        props.onClick && props.onClick(e);
    };

    return <Button {...props} onClick={handleClick} />;
};

export const GelButtonWithAnalytics = (props) => {
    // allows clients to set or override props for Google Analytics
    const {gaProps = {}} = props;

    const handleClick = (e) => {
        analyticsUtil.pushComponentAnalytics(
            {
                type: !!props.variant ? `button_${props.variant}` : 'button_primary',
                text: e.target.textContent,
                sectionId: 'page_content',
                destUrl: props.href,
                ...gaProps
            },
        );

        props.onClick && props.onClick(e);
    };

    return <GelButton {...props} onClick={handleClick} />;
};