import {combineReducers} from 'redux';
import userReducer from '../components/user/userReducer';
import layoutReducer from '../components/layout/layoutReducer';
import {transactionReducer} from 'transaction';
import dashboardReducer from '../components/dashboard/dashboardReducer';
import licenceManagementReducer from '../components/account/LicenceManagement/licenceManagementReducer';
import tooltipReducer from '../tooltip/tooltipReducer';
import navMenuReducer from '../dashboard/navMenu/navMenuReducer';

export default combineReducers({
    user: userReducer,
    tooltip: tooltipReducer,
    navMenu: navMenuReducer,
    layout: layoutReducer,
    transaction: transactionReducer,
    dashboard: dashboardReducer,
    licenceManager: licenceManagementReducer
});
