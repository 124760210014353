import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import {css} from 'styled-components';
import {media} from '@snsw/react-component-library';
import {space} from '@snsw/react-component-library';
import SectionBlock from '../Blocks/SectionBlock.styled';
import {GelTokens} from '../index';

const FullWidthSection = styled.div`
    ${space};
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: #242934;
    background-color: ${props => props.backgroundColor || '#FFF'};
`;

export const PageFullWidthSection = styled(FullWidthSection)`
    flex-grow: 1;
`;

export const PageSectionBlock = styled(SectionBlock)`
    flex-grow: 1;
    align-self: unset;
`;

export const Spacer = styled.div`
  width: 100%;
  
  ${props => !isNaN(props.md) && css`
      height: ${props.md}rem;
  `}
  
  ${props => !isNaN(props.xs) && css`
    @media(max-width: ${GelTokens.breakpoints.sm}px) {
      height: ${props.xs}rem; 
    }
  `};
`;

Spacer.propTypes = {
    md: PropTypes.number,
    xs: PropTypes.number
};

// ############################################## //
// ############## Layout Container ############## //
// ############################################## //

const Content = styled.div`
`;

const SideBar = styled.div`
    > div {
        margin-bottom: 0.5rem;
    }
`;

export const LayoutContainer = styled.div`
    display: flex;
    margin-bottom: 1.5rem;
    
    ${SideBar} {
      flex-basis: 30%;
    }
    
    ${Content} {
      flex-basis: 70%;
    }
    
    > :first-child {
      margin-right: 2rem;
    }

    :last-child {
      margin-bottom: 4rem;
    }
    
    @media (max-width: ${media.lg}px) {
        flex-direction: column;
        
        > :first-child {
            margin-right: 0;        
            margin-bottom: 2rem;
        }
    }
`;

export const Header = styled(LayoutContainer)`
  margin-bottom: 0;
`;

LayoutContainer.Header = Header;
LayoutContainer.SideBar = SideBar;
LayoutContainer.Content = Content;
