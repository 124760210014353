/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/rules-of-hooks */
// Experimental. FYI, React hook rules deter this HOC setup, in favour of usig <Context>. However, we felt Context
// was rather cumbersome, and required too huge a change on every single component so went this way.
import React, {useEffect, useState} from 'react';
import {sharedApi} from 'common';

// https://test.snsw-d8.snsw.skpr.dev/jsonapi/microcontent/collection/?filter[label]=${collectionName}&include=contents

const WithDrupalContent = (ComponentToRender) => props => {
    const [drupalData, setDrupalData] = useState();
    useEffect(() => {
        const fetchContent = () => {
            sharedApi.getCollectionByLabel(props.location.pathname).then(d => setDrupalData(d));
        };
        fetchContent();
    }, [props.location.pathname, props.location.search]);
    const newProps = {...props, ...drupalData};
    return <ComponentToRender {...newProps} />;
};

export default WithDrupalContent;
