import React, {useEffect} from 'react';
import {InPageAlert} from '@snsw-gel/react';
import {analyticsUtil} from '../utilities/analyticsUtil';

export const InPageAlertWithAnalytics = (props) => {

    useEffect(() => {
        if (props.variant !== 'info') {
            analyticsUtil.pushAlertAnalytics(
                {
                    type: `alert ${props.variant}`,
                    name: 'page notice',
                    text: `${props.text ? props.text : props.title}`,
                    errorPos: 'page content',
                },
            );
        }
    }, [props.title, props.variant, props.text]);

    return <InPageAlert {...props} />;
};
