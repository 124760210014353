import api from './api';
import env from '@beam-australia/react-env';

export const apiHosts = {
    development: '/api',
    production: 'https://api.g.service.nsw.gov.au',
    ci: 'https://api-it1.g.testservicensw.net',
    sit: 'https://api-it3.g.testservicensw.net',
    acceptance: 'https://api-psm.g.testservicensw.net',
    preprod: 'https://api-lt.g.testservicensw.net',
};
const sharedApi = {
    getApiHost: () => apiHosts[env('NODE_ENV')] || `https://api-${env('NODE_ENV')}.g.testservicensw.net`,
    getPostCodeAndSuburbSuggestion: async (value) => {
        const suggestions = await api.getSecure(`${sharedApi.getApiHost()}/etdb-shared-api/v1/lga/getPostCodeAndSuburbSuggestion/${value}`);
        return suggestions?.map(result => ({
            realValue: result,
            displayValue: `${result.suburbName.toLowerCase().replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase())}, ${result.postCode}`,
        }));
    },
    getCollectionByLabel:  (label) => api.getSecure(`${sharedApi.getApiHost()}/etdb-shared-api/v1/microcontent/collection?label=${label}`)
};
export default sharedApi;