import loginApi from './loginApi';
import env from '@beam-australia/react-env';

const getToken = async (location, isOkta = false) => {
    const code = extractAuthCodeFromURL(location);
    if (!code) {
        return null;
    }
    await loginApi.token(code, isOkta);
};

const extractAuthCodeFromURL = (location) => {
    const params = new URLSearchParams(location.search);
    return params.get('code');
};

const extractSavedPath = (location) => {
    const state = extractState(location);
    if (state && state.indexOf('c2F2ZWRQYXRoP') === 0) {
        const savedPath = atob(state).substr(10);
        const ctxPath = env('CONTEXT_PATH');
        if (ctxPath && ctxPath !== '/' && savedPath.startsWith(ctxPath)) {
            return savedPath.substr(ctxPath.length);
        }
        return savedPath;
    }
    return null;
};

const extractState = (location) => {
    if (location.search) {
        const params = new URLSearchParams(location.search);
        return params.get('state');
    }
    return null;
};

export default {
    getToken,
    extractSavedPath,
};
