import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import tokenHandler from '../utilities/tokenHandler';
import {GelComponentLoader} from '../index';

export const OAuthRedirect = ({history, location, redirectTo = '/', postLoginHandler, okta}) => {
    useEffect(() => {
            tokenHandler.getToken(location, okta).then((data) => {
                postLoginHandler && postLoginHandler();
                const savedPath = tokenHandler.extractSavedPath(location);
                history.replace(savedPath || redirectTo);
            }).catch(() => {
                history.replace('/');
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <GelComponentLoader fullPage/>;
};

OAuthRedirect.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    redirectTo: PropTypes.string,
    postLoginHandler: PropTypes.func
};

export default OAuthRedirect;
